import { Color3, Color4, DynamicTexture, FloatArray, Matrix, Mesh, MeshBuilder, MeshUVSpaceRenderer, Node, PBRMaterial, PBRMetallicRoughnessMaterial, Quaternion, RenderTargetTexture, StandardMaterial, Vector3, VertexBuffer } from "@babylonjs/core";
import { attachments, get_attachment_offset, offset } from "./attachment_offset";
import { concave_transparent } from "./concave_transparent";
import { DECAL_ALPHA_INDEX, DECAL_PREVIEW_ALPHA_INDEX, TRANSPARENT_ALPHA_INDEX, decal, eball_clasp, ebite, esplint, ethickness, eupdown, lerp, model_paint_state, mrcolor4, sticker_size, unlerp } from "./state";

function nosup(){ throw new Error('not supported'); }

type paint_rc = {x: number, y: number, w: number, h: number, c: mrcolor4};

function paint(tex: DynamicTexture, rc: paint_rc){
    let size = tex.getSize();
    let ctx = tex.getContext();
    ctx.fillStyle = rc.c.c.toHexString();
    ctx.fillRect(rc.x * size.width, rc.y * size.height, rc.w * size.width, rc.h * size.height);
}

function paint_rects(base: DynamicTexture, mr: DynamicTexture, rect01s: paint_rc[]){
    for(let rc01 of rect01s){
        paint(base, rc01);
        paint(mr, {...rc01, c: {
            m: rc01.c.m,
            r: rc01.c.r,
            c: mr_to_color(rc01.c),
        }});
    }

    base.update();
    mr.update();
}

function mr_to_color(mr: {m: number, r: number}){
    return new Color4(0, mr.r, mr.m, 1)
}

function replace_alpha(c: Color4, a: number){
    return new Color4(
        c.r,
        c.g,
        c.b,
        a,
    );
}

function render_decals(reciever: Mesh, decals: decal[], zoffset: number, zunits: number){        
    for(let c of reciever.getChildMeshes(true, n => n.name === 'decal')){
        c.material?.dispose();
        c.dispose();
    }

    for(let d of decals){
        let decal_mesh = MeshBuilder.CreateDecal(
            'decal',
            reciever,
            {
                position: d.position,
                normal: d.normal,
                // minus x for xmirror
                size: new Vector3(-sticker_size * d.scale * (d.hmirror ? -1 : 1), sticker_size * d.scale, 5),
                angle: d.angle_rad,
                captureUVS: false,
                cullBackFaces: true,
                localMode: true,
            }
        )

        // alpha index by decal index
        decal_mesh.alphaIndex = DECAL_ALPHA_INDEX;
        decal_mesh.parent = reciever;
        
        const decalMaterial = new StandardMaterial("decalMat", decal_mesh.getScene());
        decalMaterial.zOffset = zoffset;
        decalMaterial.zOffsetUnits = zunits;
        decalMaterial.diffuseTexture = d.tex;

        // this makes material alpha blended
        decalMaterial.diffuseTexture.hasAlpha = true;
        decalMaterial.useAlphaFromDiffuseTexture = true;
        decal_mesh.material = decalMaterial;

        d.mesh = decal_mesh;
    }
}

function preview_decal(decalmap: MeshUVSpaceRenderer, decal_receiver: Mesh, preview?: decal){
    // const {decalmap} = this;
    // BABYLON BUG : mandatory!!!
    // why teporalily disable useOrderIndependentTransparency?
    // under useOrderIndependentTransparency, render decalmap screw engine state with some internal error
    // let useOIT = context.scene.useOrderIndependentTransparency;
    // decal_receiver.getScene().useOrderIndependentTransparency = false;

    // console.log((decalmap.texture as RenderTargetTexture).isReady());

    decalmap.clear();
    
    if(preview){
        // BABYLON BUG : MUST reset camera viewport before decalmap.renderTexture and restore after it
        // const {decal_receiver} = this;
        const cam = decal_receiver.getScene().cameras[0];
        const {x, y, width, height} = cam.viewport;

        cam.viewport.x = 0;
        cam.viewport.y = 0;
        cam.viewport.width = 1;
        cam.viewport.height = 1;

        // const renderList = (decalmap.texture as RenderTargetTexture).renderList!;
        // if(!renderList.includes(decal_receiver)){
        //     renderList.push(decal_receiver);
        // }

        decalmap.renderTexture(                                            
            preview.tex,
            preview.position,
            preview.normal,
            // minus x for xmirror
            new Vector3(-sticker_size * preview.scale * (preview.hmirror ? -1 : 1), sticker_size * preview.scale, 5),
            preview.angle_rad,
        );

        // console.log(decalmap.texture);
        // console.log((decalmap.texture as RenderTargetTexture).isReady());

        cam.viewport.x = x;
        cam.viewport.y = y;
        cam.viewport.width = width;
        cam.viewport.height = height;
    }

    // decal_receiver.getScene().useOrderIndependentTransparency = useOIT;
}


function paint_sprint(SM: PBRMaterial, mrc4: mrcolor4): void {       
// function paint_sprint(SM: StandardMaterial, c4: Color4): void {       
    // const avg = (c4.r + c4.g + c4.b) / 3;
    // c4 = c4.clone();
    // const avg = 1;
    // c4.r = lerp(c4.r, avg, 0.5);
    // c4.g = lerp(c4.g, avg, 0.5);
    // c4.b = lerp(c4.b, avg, 0.5);
    console.log('paint_sprint', mrc4.m);
    const c3 = new Color3(mrc4.c.r, mrc4.c.g, mrc4.c.b);
    SM.albedoColor = c3;
    // SM.diffuseColor = c3;
    // SM.specularColor = c3;
    // SM.alpha = mrc4.c.a * 0.26;
    SM.alpha = 0.57;
    SM.emissiveColor = new Color3(mrc4.c.r*0.5, mrc4.c.g*0.5, mrc4.c.b*0.5);
    if (mrc4.c.r === 1 && mrc4.c.g === 1 && mrc4.c.b === 1) {
        if (mrc4.m === 0) {
            SM.emissiveColor = new Color3(1, 1, 1);
        }
        else {
            SM.alpha = 0.26;
            SM.emissiveColor =  new Color3(0, 0, 0);
        }
    }

}

export class model{
    static scratch_canvas: HTMLCanvasElement;
    static teeth: HTMLImageElement;
    static topline: HTMLImageElement;
    static mix_image0: HTMLImageElement;
    static mix_image1: HTMLImageElement;

    stripe(mrcolors: mrcolor4[]){nosup()}
    chequered(mrc0: mrcolor4, mrc1: mrcolor4){nosup()}
    mixed(mrcolors: mrcolor4[]){nosup()}
    dracular(mrc0: mrcolor4, mrc1: mrcolor4, mrc2: mrcolor4){nosup()}
    splint(mrc: mrcolor4){nosup()}
    attachment(lingual_bar: boolean, ball_clasp: eball_clasp){nosup();}
    dispose(){nosup()}
    get decalmap(): MeshUVSpaceRenderer{nosup(); return null as any as MeshUVSpaceRenderer}
    get decal_receiver(): Mesh{nosup(); return null as any as Mesh}
    set short_covered(show: boolean){nosup()}
    set long_covered(show: boolean){nosup()}

    pick(){
        const {decal_receiver} = this;
        const scene = decal_receiver.getScene();
        return scene.pick(
            scene.pointerX, 
            scene.pointerY, 
            (m) => m === decal_receiver,
            false,
            null,
        );
    }

    preview_decal(preview?: decal){
        preview_decal(this.decalmap, this.decal_receiver, preview);
    }

    render_decals(decals: decal[]){                
        render_decals(this.decal_receiver, decals, -1, -1);
    }
}

class uvswitch{
    private uv_normal: FloatArray;
    private uv_stripe: FloatArray;
    
    constructor(public model: Mesh, public xrange?: [number, number]){
        let positions = model.getVerticesData(VertexBuffer.PositionKind)!;
        console.assert(positions.length % 3 === 0);
        if(!xrange){
            let xmin = Number.MAX_VALUE; 
            let xmax = Number.MIN_VALUE; 
            for(let i = 0; i < positions.length; i += 3){
                let x = positions[i];
                xmin = Math.min(x, xmin);
                xmax = Math.max(x, xmax);
            }        
            this.xrange = [xmin, xmax];
        }
        

        let uvs = model.getVerticesData(VertexBuffer.UVKind)!;
        console.assert(uvs.length % 2 === 0);
        this.uv_normal = uvs.slice();
        this.uv_stripe = uvs.slice();

        for(let i = 0; i < positions.length; i += 3){
            let x = positions[i];
            let x01 = unlerp(this.xrange![0], this.xrange![1], x);
            this.uv_stripe[2 * (i / 3) + 1] = x01;
        }

        model.markVerticesDataAsUpdatable(VertexBuffer.UVKind, true);
    }

    stripe(){
        this.model.updateVerticesData(VertexBuffer.UVKind, this.uv_stripe);
    }

    normal(){
        this.model.updateVerticesData(VertexBuffer.UVKind, this.uv_normal);
    }
}

export class sportsguard extends model{
    base: uvswitch;
    long_cover: uvswitch;
    short_cover: uvswitch;

    base_decal: decal_host;
    long_cover_decal: decal_host;
    short_cover_decal: decal_host;

    basetexture: DynamicTexture;
    mrtexture: DynamicTexture;
    
    static create_instant_class(thick: ethickness, bite: ebite){
        const no_bite = bite === ebite.none;
        const is_thick = thick === ethickness.thick;
        class sportsguard_class_instance extends sportsguard{
            
            static prefix: string = 
                !is_thick && no_bite ? 'sportsguard-4mm' :
                !is_thick && !no_bite ? 'sportsguard-4mm-bite' :
                is_thick && no_bite ? 'sportsguard-5mm' :
                is_thick && !no_bite ? 'sportsguard-5mm-bite' : 'sportsguard-4mm';

            static get path(){
                return `models/${this.prefix}.glb`;
            }
                
            constructor(root: Node){
                super(root, sportsguard_class_instance.prefix);
            }
    
            get thick(){ return thick; }
            get bite(){ return bite; }
        }
        return sportsguard_class_instance;
    }
    
    constructor(private root: Node, prefix: string){
        super();
        this.base = new uvswitch(root.getChildren().find(n => n.name === `${prefix}-model`) as Mesh);
        this.long_cover = new uvswitch(root.getChildren().find(n => n.name === `${prefix}-long-cover`) as Mesh, this.base.xrange);
        this.short_cover = new uvswitch(root.getChildren().find(n => n.name === `${prefix}-short-cover`) as Mesh, this.base.xrange);

        let base_material = root.getScene().materials.find(m => m.name === 'sportsguard model')!;
        let cover_material = root.getScene().materials.find(m => m.name === 'sportsguard cover')!;        

        this.base.model.material = base_material;
        this.long_cover.model.material = cover_material;
        this.short_cover.model.material = cover_material;
        this.long_cover.model.alphaIndex = TRANSPARENT_ALPHA_INDEX;
        this.short_cover.model.alphaIndex = TRANSPARENT_ALPHA_INDEX;

        this.base_decal = new decal_host(this.base.model);
        this.long_cover_decal = new decal_host(this.long_cover.model);
        this.short_cover_decal = new decal_host(this.short_cover.model);
        // this.base_decal = new decal_host(this.base.model);
        
        this.basetexture = (base_material as PBRMetallicRoughnessMaterial).baseTexture as DynamicTexture;
        this.mrtexture = (base_material as PBRMetallicRoughnessMaterial).metallicRoughnessTexture as DynamicTexture;
    }

    set short_covered(show: boolean){
        this.short_cover.model.setEnabled(show);
    }

    set long_covered(show: boolean){
        this.long_cover.model.setEnabled(show);
    }

    get decalmap(){
        return this.base_decal.model.decalMap!;
    }

    get decal_receiver(){
        return this.base_decal.model;
    }

    uv_stripe(){
        this.base.stripe();
        this.long_cover.stripe();
        this.short_cover.stripe();
    }

    uv_normal(){
        this.base.normal();
        this.long_cover.normal();
        this.short_cover.normal();
    }

    dispose(){
        this.root.dispose();
    }    

    paint_self(rect01s: paint_rc[]){
        paint_rects(this.basetexture, this.mrtexture, rect01s);
    }

    stripe(mrcolors: mrcolor4[]){
        // we make first color go to left side
        mrcolors = mrcolors.slice().reverse();

        console.assert(mrcolors.length > 0 && mrcolors.length < 6);

        let heights01 = 
            mrcolors.length === 1 ? [1] :
            mrcolors.length === 2 ? [0.5, 0.5] :
            mrcolors.length === 3 ? [0.4, 0.2, 0.4] :
            mrcolors.length === 4 ? [0.3, 0.2, 0.2, 0.3] :
            mrcolors.length === 5 ? [0.3, 0.1, 0.2, 0.1, 0.3] : [1];

        let rect01s = [] as paint_rc[];

        console.assert(heights01.length === mrcolors.length);

        let t = 0;
        for(let i = 0; i < heights01.length; ++i){
            rect01s.push({
                x: 0, 
                y: t, 
                w: 1, 
                h: heights01[i], 
                // c: mrcolors[i],
                c: {...mrcolors[i], c: replace_alpha(mrcolors[i].c, 1)}
            });            
            t += heights01[i];
        }

        this.paint_self(rect01s);

        // repeat paint to remove border artifact
        this.paint_self(rect01s);
        this.paint_self(rect01s);

        // only when updateVerticesData
        this.uv_stripe();
    }

    chequered(mrc0: mrcolor4, mrc1: mrcolor4){
        let rect01s = [{
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            c: {...mrc0, c: replace_alpha(mrc0.c, 1)},
        }];
        
        const division = 20;        
        const wunit = 1 / division;
        const hunit = 1 / division;
        for(let i = 0; i < division; ++i){
            for(let j = 0; j < division; ++j){
                if((i + j) % 2 === 0){                    
                    rect01s.push({
                        x: j * wunit,
                        y: i * hunit,
                        w: wunit,
                        h: hunit,
                        c: {...mrc1, c: replace_alpha(mrc1.c, 1)},
                    });
                }
            }
        }

        this.paint_self(rect01s);

        // only when updateVerticesData
        this.uv_normal();
    }

    mixed(mrcolors: mrcolor4[]){
        function draw_tinted_transparent_image(tex: DynamicTexture, image: HTMLImageElement, c: Color4){
            model.scratch_canvas.width = image.width;
            model.scratch_canvas.height = image.height;
            let ctx = model.scratch_canvas.getContext('2d')!;
            ctx.fillStyle = c.toHexString();
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            let last_globalCompositeOperation = ctx.globalCompositeOperation;
            ctx.globalCompositeOperation = "destination-atop";
            ctx.drawImage(image, 0, 0);            
            tex.getContext().drawImage(model.scratch_canvas, 0, 0, tex.getSize().width, tex.getSize().height);            
            ctx.globalCompositeOperation = last_globalCompositeOperation;
        }

        console.log(mrcolors.slice());

        mrcolors.length > 0 && paint(this.basetexture, {
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            c: {
                ...mrcolors[0],
                c: replace_alpha(mrcolors[0].c, 1),
            },
        });
        mrcolors.length > 1 && draw_tinted_transparent_image(this.basetexture, model.mix_image0, mrcolors[1].c);
        mrcolors.length > 2 && draw_tinted_transparent_image(this.basetexture, model.mix_image1, mrcolors[2].c);
        this.basetexture.update();

        mrcolors.length > 0 && paint(this.mrtexture, {
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            c: {
                ...mrcolors[0],
                c: mr_to_color(mrcolors[0]),
            },
        });
        mrcolors.length > 1 && draw_tinted_transparent_image(this.mrtexture, model.mix_image0, mr_to_color(mrcolors[1]));
        mrcolors.length > 2 && draw_tinted_transparent_image(this.mrtexture, model.mix_image1, mr_to_color(mrcolors[2]));
        this.mrtexture.update();        
        
        // only when updateVerticesData
        this.uv_normal();
    }

    render_decals(decals: decal[]){        
        // base decal
        render_decals(this.base_decal.model, decals, -1, -1);

        // cover decal
        render_decals(this.long_cover_decal.model, this.long_cover.model.isEnabled() ? decals : [], -10, -10);
        render_decals(this.short_cover_decal.model, this.short_cover.model.isEnabled() ? decals : [], -10, -10);
    }

    preview_decal(preview?: decal){
        // base decal
        preview_decal(this.base_decal.decalmap, this.base_decal.model, preview);

        // cover decal. sharing decal texture with base
        // preview_decal(this.long_cover_decal.decalmap, this.long_cover_decal.model, this.long_cover_decal.model.isEnabled() ? preview : undefined);
        // preview_decal(this.short_cover_decal.decalmap, this.short_cover_decal.model, this.short_cover_decal.model.isEnabled() ? preview : undefined);
    }
}

export class dracular extends model{    
    model: Mesh;
    basetexture: DynamicTexture;
    mrtexture: DynamicTexture;

    static get path(){
        return 'models/dracular.glb';
    }
    constructor(private root: Node){
        super();
        this.model = root.getChildren()
            .find(n => n.name === 'dracular model')! as Mesh;

        let base_material = root.getScene().materials.find(m => m.name === 'dracular')!;
        this.model.material = base_material;
        this.basetexture = (base_material as PBRMetallicRoughnessMaterial).baseTexture as DynamicTexture;        
        this.mrtexture = (base_material as PBRMetallicRoughnessMaterial).metallicRoughnessTexture as DynamicTexture;

        // check disposed
        this.model.decalMap = new MeshUVSpaceRenderer(this.model, root.getScene(), {
			width: 2048,
			height: 2048,			
		});        

        (base_material as PBRMetallicRoughnessMaterial).decalMap!.isEnabled = true;
    }

    dispose(){
        this.root.dispose();
    }

    get decalmap(){
        return this.model.decalMap!;
    }

    get decal_receiver(){
        return this.model;
    }

    dracular(mrc0: mrcolor4, mrc1: mrcolor4, mrc2: mrcolor4){
        function draw_tinted_blacked_image(tex: DynamicTexture, image: HTMLImageElement, c: Color4){
            model.scratch_canvas.width = image.width;
            model.scratch_canvas.height = image.height;
            let ctx = model.scratch_canvas.getContext('2d')!;
            ctx.drawImage(image, 0, 0);
            let ID = ctx.getImageData(0, 0, model.scratch_canvas.width, model.scratch_canvas.height);
            for(let i = 0; i < ID.data.length; i += 4){
                const weight = (255 - ID.data[i]);
                const inverse_weight = 255 / weight;
                
                ID.data[i] = weight * c.r * inverse_weight;
                ID.data[i + 1] = weight * c.g * inverse_weight;
                ID.data[i + 2] = weight * c.b * inverse_weight;
                ID.data[i + 3] = weight;    // alpha is red
            }
            ctx.putImageData(ID, 0, 0);
            tex.getContext().drawImage(model.scratch_canvas, 0, 0, tex.getSize().width, tex.getSize().height);
            tex.vOffset = 1;
            tex.vScale = -1;
        }

        paint(this.basetexture, {
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            c: {...mrc0, c: replace_alpha(mrc0.c, 1)},
        });

        draw_tinted_blacked_image(this.basetexture, dracular.topline, mrc2.c);
        draw_tinted_blacked_image(this.basetexture, dracular.teeth, mrc1.c);
        
        this.basetexture.update();

        paint(this.mrtexture, {
            x: 0,
            y: 0,
            w: 1,
            h: 1,
            c: {m: mrc0.m, r: mrc0.r, c: mr_to_color(mrc0)},
        });
        draw_tinted_blacked_image(this.mrtexture, dracular.topline, mr_to_color(mrc2));
        draw_tinted_blacked_image(this.mrtexture, dracular.teeth, mr_to_color(mrc1));

        this.mrtexture.update();  
    }
}

class decal_host{
    model: Mesh;
    constructor(public template: Mesh){
        const model = new Mesh(
            `decal-preview(${template.name})`, 
            template.getScene(),
            template,
            template,
        );
        model.isVisible = true;
        model.position = Vector3.Zero();
        model.rotationQuaternion = Quaternion.Identity();
        model.scaling = Vector3.One();
        model.alphaIndex = DECAL_PREVIEW_ALPHA_INDEX;
        model.material = template.getScene().materials.find(m => m.name === 'decal-preview')!;
        model.decalMap = new MeshUVSpaceRenderer(model, model.getScene(), 
            // {
            //     width: 2048,
            //     height: 2048,
            // }
        );
        // model.decalMap.isReady();
        // (model.material as StandardMaterial).opacityTexture = model.decalMap.texture;
        model.decalMap.texture = (model.material as StandardMaterial).opacityTexture as RenderTargetTexture;
        (model.material as StandardMaterial).decalMap!.isEnabled = true;
        this.model = model;
    }

    get decalmap(){
        return this.model.decalMap!;
    }
}

export class splint extends model{    
    static async create_instant_class(state: model_paint_state, attachments: attachment_templates){
        const {splint_type, updown, thickness, bite} = state;
        const preprefix = {
            [esplint.splint]: 'splint',
            [esplint.ferrar]: 'ferrar-upper',
            [esplint.gelb]: 'gelb',
            [esplint.gelb_split]: 'gelb-split',
            [esplint.NTI]: 'NTI',
            [esplint.kois]: 'kois-upper',
        }[splint_type];

        const updownfix = 
            splint_type === esplint.splint && updown === eupdown.upper ? '-upper' :
            splint_type === esplint.splint && updown === eupdown.lower ? '-lower' :
            splint_type === esplint.splint ? '-upper' :
            '';

        const thickfix: string = `${thickness === ethickness.thick ? '-3mm' : '-2mm'}`;

        const bitefix = 
            splint_type === esplint.splint && bite === ebite.all ? '-all-bite' :
            splint_type === esplint.splint && bite === ebite.molar ? '-molar-bite' :
            bite !== ebite.none ? '-bite' :
            '';
        
        const prefix = `${preprefix}${updownfix}${thickfix}${bitefix}`;
        const attachment_offset = await get_attachment_offset(prefix);

        class splint_class_instance extends splint{
            static get path(){
                return `models/${prefix}.glb`;
            }
                
            constructor(root: Node){
                super(root, prefix, updown, attachments, attachment_offset);
            }
        }
        return splint_class_instance;
    }
    
    CT: concave_transparent;
    decal_host: decal_host;

    attachments_root: Mesh;    
    attachments: attachments<Mesh>

    constructor(
        private root: Node, 
        readonly prefix: string, 
        updown: eupdown,
        attachments: attachment_templates,
        offsets: attachments<offset>,
    ){
        super();

        const template = root.getChildren()
            .find(n => n.name === `${prefix}-model`)! as Mesh;

        this.CT = new concave_transparent(template);

        let base_material = root.getScene().materials.find(m => m.name === 'plasticMaterial')!;
        // let base_material = root.getScene().materials.find(m => m.name === 'splint')!;        

        this.CT.model.material = base_material;
        
        this.decal_host = new decal_host(template);

        this.attachments_root = new Mesh('attachments_root', root.getScene(), root);
        this.attachments_root.scaling = new Vector3(
            1, 
            updown === eupdown.upper ? 1 : -1, 
            1
        ); 

        ///

        const templates: attachments<attachment> = {
            lingual_bar: attachments.lingual_bar,
            ball_clasp_left_45: attachments.ball_clasp.lrb,
            ball_clasp_left_56: attachments.ball_clasp.lrb,
            ball_clasp_left_67: attachments.ball_clasp.lrf,
            ball_clasp_right_45: attachments.ball_clasp.rlb,
            ball_clasp_right_56: attachments.ball_clasp.rlb,
            ball_clasp_right_67: attachments.ball_clasp.rlf,
        };

        // remove previous instance
        for(let m of this.CT.model.getChildMeshes(true, n => Object.keys(templates).includes(n.name))){
            m.dispose();
        }

        this.attachments = {} as attachments<Mesh>;

        // attach if required
        for(let k in templates){
            const typed = k as keyof attachments<void>;
            const clone = new Mesh(
                k, 
                this.attachments_root.getScene(),
                this.attachments_root,
                templates[typed].model
            );

            clone.isVisible = true;

            const offset = offsets[typed];
            clone.position = new Vector3(offset.px, offset.py, offset.pz);
            clone.rotation = new Vector3(offset.rx, offset.ry, offset.rz);
            clone.scaling = new Vector3(offset.sx, offset.sy, offset.sz);
            this.attachments[typed] = clone;
        }
    }

    dispose(){
        this.CT.dispose();
        this.root.dispose();
    }

    get decalmap(){
        return this.decal_host.model.decalMap!;
    }

    get decal_receiver(){
        return this.decal_host.model;
    }

    splint(mrc: mrcolor4): void {       
        paint_sprint(this.CT.model.material as PBRMaterial, mrc);
        // paint_sprint(this.CT.model.material as StandardMaterial, mrc.c);
    }

    attachment(lingual_bar: boolean, ball_clasp: eball_clasp){
        const {attachments} = this;

        attachments.lingual_bar.isVisible = lingual_bar;
        attachments.ball_clasp_left_45.isVisible = 
            attachments.ball_clasp_right_45.isVisible = 
                ball_clasp === eball_clasp.double || ball_clasp === eball_clasp.triple;

        attachments.ball_clasp_left_56.isVisible = 
            attachments.ball_clasp_right_56.isVisible = 
                ball_clasp === eball_clasp.single || ball_clasp === eball_clasp.triple;

        attachments.ball_clasp_left_67.isVisible = 
            attachments.ball_clasp_right_67.isVisible = 
                ball_clasp === eball_clasp.double || ball_clasp === eball_clasp.triple;
    }
}


//  updown mirror test
export class double_splint extends model{
    CT: concave_transparent;
    decal_host: decal_host;

    constructor(private upper: splint, private lower: splint){
        super();

        const manipulate_vertices = (mesh: Mesh, offset: Vector3, umin: number, umax: number) => {
            const m = Matrix.Identity();        
            m.setTranslation(offset);            
            mesh.bakeTransformIntoVertices(m);

            const uvs = mesh.getVerticesData(VertexBuffer.UVKind)!.slice();
            for(let i = 0; i < uvs.length; i += 2){
                uvs[i] = lerp(umin, umax, uvs[i]);
            }
            mesh.markVerticesDataAsUpdatable(VertexBuffer.UVKind, true);
            mesh.updateVerticesData(VertexBuffer.UVKind, uvs);
        }

        // const zoffset = 7.5;
        const zoffset = 8.5;

        manipulate_vertices(upper.CT.template, new Vector3(2, 2, zoffset), 0, 0.5);
        upper.attachments_root.position = new Vector3(2, zoffset, -2);
        manipulate_vertices(lower.CT.template, new Vector3(0, 0, -zoffset), 0.5, 1);
        lower.attachments_root.position = new Vector3(0, -zoffset, 0);
        
        const template = Mesh.MergeMeshes(            
            [upper.CT.template, lower.CT.template],
        )!;
        template.name = `merged(${upper.CT.template.name}, ${upper.CT.template.name}`;

        this.decal_host = new decal_host(template);

        upper.CT.model.isVisible = false;
        lower.CT.model.isVisible = false;

        this.CT = new concave_transparent(template);
        this.CT.model.material = this.CT.model.getScene().materials.find(m => m.name === 'splint')!;

        // decal mask
        const decal_mask_parent = this.decal_host.model;
        const decal_mask = new Mesh(
            'decal-mask', 
            decal_mask_parent.getScene(),
            decal_mask_parent,
            decal_mask_parent,
        );
        decal_mask.isVisible = true;
        decal_mask.position = Vector3.Zero();
        decal_mask.rotationQuaternion = Quaternion.Identity();
        decal_mask.scaling = Vector3.One();
        decal_mask.alphaIndex = DECAL_ALPHA_INDEX - 1;
        decal_mask.material = decal_mask_parent.getScene().materials.find(m => m.name === 'decal-mask')!;
    }

    dispose(){
        this.upper.dispose();
        this.lower.dispose();
        this.CT.dispose();
        this.CT.template.dispose();
    }

    get decalmap(){
        return this.decal_host.decalmap;
    }

    get decal_receiver(){
        return this.decal_host.model;
    }

    splint(mrc: mrcolor4): void {        
        // paint_sprint(this.CT.model.material as StandardMaterial, mrc.c);
        paint_sprint(this.CT.model.material as PBRMaterial, mrc);
    }

    attachment(lingual_bar: boolean, ball_clasp: eball_clasp){
        this.upper.attachment(lingual_bar, ball_clasp);
        this.lower.attachment(lingual_bar, ball_clasp);
    }
}

export type attachment_templates = {
    lingual_bar: attachment,
    ball_clasp: {
        lrb: attachment,
        lrf: attachment,
        rlb: attachment,
        rlf: attachment,
    }
}

export class attachment{
    model: Mesh;
    static create_instant_class(prefix: string){
        return class instant_class extends attachment{
            static get path(){
                return `models/${prefix}.glb`;
            }

            constructor(root: Node){
                super(root, prefix)
            }
        }
    }
    constructor(root: Node, prefix: string){
        this.model = root.getChildMeshes(true, (n) => n.name === `${prefix}-model`)[0] as Mesh;
        let material = root.getScene().materials.find(m => m.name === 'attachment')!;
        this.model.material = material;

        this.model.isVisible = false;
    }
}